import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const STEMResearch = () => {
  const pageTitle = "STEM Research Program | Advanced Research Projects | BLAST";
  const pageDescription = "Join our comprehensive STEM research program focused on AI and technology. Work on advanced research projects, learn from expert mentors, and develop crucial STEM skills. Summer 2025 applications open.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalProgram",
    "name": "STEM Research Program at BLAST AI",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org",
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ]
    },
    "programType": "Research Program",
    "educationalProgramMode": "online",
    "timeOfYear": "Summer",
    "applicationDeadline": "2025-04-30",
    "educationalLevel": "High School",
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "STEM Research Methods",
      "Data Science",
      "Scientific Computing",
      "Technical Writing",
      "Research Design",
      "Project Implementation"
    ],
    "alumni": {
      "@type": "Person",
      "description": "Our students have won national STEM competitions and continued to leading STEM programs"
    },
    "educationalCredentialAwarded": "STEM Research Certificate and Project Portfolio"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/stem-research" />
        <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
        <meta property="og:site_name" content="BLAST AI" />
        <meta property="og:locale" content="en_US" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
        
        {/* Additional SEO tags */}
        <meta name="keywords" content="stem research program, stem research opportunities, stem projects, science research program, technology research, stem education" />
        <meta name="author" content="BLAST AI" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://blastai.org/stem-research" />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Research />
    </>
  );
};

export default STEMResearch; 