import React from 'react';
import { Helmet } from 'react-helmet-async';
import SectionHeader from '../components/sections/partials/SectionHeader';
import BLASTTestimonials from '../components/sections/BLASTTestimonials';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';
import Image from '../components/elements/Image';
import Pricing from '../components/sections/Pricing';
import ApplicationTimeline from '../components/sections/ApplicationTimeline';

const PR = () => {
  const community = {
    title: 'The best collaboration forms through fun',
    paragraph: 'With BLAST\'s house system (just like Hogwarts!), students participate in weekly community events and compete for points. Games range from mafia to .io games and NBA jeopardy!'
  };

  return (
    <>
      <Helmet>
        <title>BLAST AI Testimonials</title>
        <meta name="description" content="Read what our past students have to say about their experience with BLAST AI" />
    </Helmet>
      <div className="center-content padded-content section-header bg-gradient-to-b from-blue-200 to-white">
        <SectionHeader data={community} className="center-content pt-20" />
        <Image
          src={`${process.env.REACT_APP_CLOUDFLARE_URL}/jeopardy.jpg`}
          alt="Testimonial 03"
          width={700}
          height={48} 
          // className="pb-20"
        />
      </div>
      <BLASTTestimonials />
      <ApplicationTimeline topDivider className="has-bg-color-cut illustration-section-02"/>
    </>
  );
};

export default PR;