import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const ResearchFellowship = () => {
  const pageTitle = "Research Fellowship Program | Elite Research Training | BLAST";
  const pageDescription = "Join our prestigious research fellowship program. Collaborate with leading researchers, receive advanced training in AI and machine learning, and contribute to groundbreaking research. Summer 2025 applications open.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalProgram",
    "name": "Research Fellowship Program at BLAST AI",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org",
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ]
    },
    "programType": "Fellowship Program",
    "educationalProgramMode": "online",
    "timeOfYear": "Summer",
    "applicationDeadline": "2025-04-30",
    "educationalLevel": "High School",
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "Advanced Research Methods",
      "Research Leadership",
      "Scientific Writing",
      "Research Ethics",
      "Project Management",
      "Academic Publishing"
    ],
    "alumni": {
      "@type": "Person",
      "description": "Our fellows have gone on to prestigious research positions and top universities"
    },
    "educationalCredentialAwarded": "Research Fellowship Certificate and Published Research"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/research-fellowship" />
        <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
        <meta property="og:site_name" content="BLAST AI" />
        <meta property="og:locale" content="en_US" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
        
        {/* Additional SEO tags */}
        <meta name="keywords" content="research fellowship program, research fellowship, academic fellowship, student fellowship, research training program, elite research program" />
        <meta name="author" content="BLAST AI" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://blastai.org/research-fellowship" />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Research />
    </>
  );
};

export default ResearchFellowship; 