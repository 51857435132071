import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const CollegeApplicationResearch = () => {
  const pageTitle = "College Application Research Program | Boost Your College Apps | BLAST";
  const pageDescription = "Strengthen your college applications with our mentor-guided research program. Work with Stanford/MIT mentors, publish papers, and stand out to top universities. Summer 2025 applications open.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalProgram",
    "name": "College Application Research Program at BLAST AI",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org",
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ]
    },
    "programType": "Research Program",
    "educationalProgramMode": "online",
    "timeOfYear": "Summer",
    "applicationDeadline": "2025-04-30",
    "educationalLevel": "High School",
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "Research Methodology",
      "Academic Writing",
      "College Application Strategy",
      "Machine Learning",
      "Publication Process",
      "Research Portfolio Development"
    ],
    "alumni": {
      "@type": "Person",
      "description": "Our alumni have been accepted to top universities including Stanford, MIT, Harvard, and Princeton"
    },
    "educationalCredentialAwarded": "Research Publication and College Application Portfolio"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/college-application-research" />
        <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
        <meta property="og:site_name" content="BLAST AI" />
        <meta property="og:locale" content="en_US" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
        
        {/* Additional SEO tags */}
        <meta name="keywords" content="college application research, research for college apps, college admissions research, research program college applications, college research opportunities, college application boost" />
        <meta name="author" content="BLAST AI" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://blastai.org/college-application-research" />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Research />
    </>
  );
};

export default CollegeApplicationResearch; 