import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const SummerResearchInternship = () => {
  const pageTitle = "Summer Research Internship Program | AI Research Experience | BLAST";
  const pageDescription = "Gain hands-on AI research experience through our summer internship program. Work with leading researchers, develop cutting-edge projects, and build your research portfolio. Summer 2025 applications open.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalProgram",
    "name": "Summer Research Internship Program at BLAST AI",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org",
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ]
    },
    "programType": "Internship Program",
    "educationalProgramMode": "online",
    "timeOfYear": "Summer",
    "applicationDeadline": "2025-04-30",
    "educationalLevel": "High School",
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "Research Skills",
      "Project Management",
      "Technical Writing",
      "Data Analysis",
      "AI Development",
      "Research Presentation"
    ],
    "alumni": {
      "@type": "Person",
      "description": "Our interns have gone on to secure positions at leading tech companies and research institutions"
    },
    "educationalCredentialAwarded": "Research Internship Certificate and Project Portfolio"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/summer-research-internship" />
        <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
        <meta property="og:site_name" content="BLAST AI" />
        <meta property="og:locale" content="en_US" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
        
        {/* Additional SEO tags */}
        <meta name="keywords" content="summer research internship, research internship program, ai research internship, summer research program, research experience, student internship program" />
        <meta name="author" content="BLAST AI" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://blastai.org/summer-research-internship" />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Research />
    </>
  );
};

export default SummerResearchInternship; 